var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "el-breadcrumb",
        {
          staticStyle: { "margin-top": "20px" },
          attrs: { "separator-class": "el-icon-arrow-right" },
        },
        [
          _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
            _vm._v("首页"),
          ]),
          _c("el-breadcrumb-item", { attrs: { to: { path: "/warehouse" } } }, [
            _vm._v("仓储"),
          ]),
          _c("el-breadcrumb-item", [_vm._v("异常件录入")]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "search-form",
                  attrs: {
                    rules: _vm.validateForm,
                    model: _vm.searchForm,
                    "label-width": "120px",
                  },
                  nativeOn: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return (() => {}).apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "打印面单", prop: "" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        model: {
                          value: _vm.autoPrintLabel,
                          callback: function ($$v) {
                            _vm.autoPrintLabel = $$v
                          },
                          expression: "autoPrintLabel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分类", prop: "cate" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.searchForm.cate,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "cate", $$v)
                            },
                            expression: "searchForm.cate",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1, border: "" } }, [
                            _vm._v("未下单"),
                          ]),
                          _c("el-radio", { attrs: { label: 2, border: "" } }, [
                            _vm._v("需退回"),
                          ]),
                          _c("el-radio", { attrs: { label: 3, border: "" } }, [
                            _vm._v("其他"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户编码", prop: "user_code" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "用户编码" },
                        model: {
                          value: _vm.searchForm.user_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "user_code", $$v)
                          },
                          expression: "searchForm.user_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单号", prop: "waybill" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "入仓单号/GLP单号",
                        },
                        model: {
                          value: _vm.searchForm.waybill,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "waybill", $$v)
                          },
                          expression: "searchForm.waybill",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          clearable: "",
                          placeholder:
                            "说明异常件具体原因:如超尺寸、XX违禁品等",
                        },
                        model: {
                          value: _vm.searchForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "remark", $$v)
                          },
                          expression: "searchForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "工单内容", prop: "work_order_content" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          clearable: "",
                          placeholder:
                            "新建异常件会自动创建teambition工单;请不要清空",
                          rows: 4,
                        },
                        model: {
                          value: _vm.searchForm.work_order_content,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "work_order_content", $$v)
                          },
                          expression: "searchForm.work_order_content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传图片" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-image",
                          attrs: {
                            action: "#",
                            "list-type": "picture-card",
                            accept:
                              "image/x-png,image/gif,image/jpeg,image/svg",
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.onRemoveImage,
                            "file-list": _vm.uploadFileList,
                            "http-request": (file) => _vm.uploadFile(file),
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上架货架号", prop: "shelf_code" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "上架货架号" },
                        model: {
                          value: _vm.searchForm.shelf_code,
                          callback: function ($$v) {
                            _vm.$set(_vm.searchForm, "shelf_code", $$v)
                          },
                          expression: "searchForm.shelf_code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "z-button",
                        { attrs: { type: "primary" }, on: { click: _vm.save } },
                        [_vm._v("提交")]
                      ),
                      _vm.isWechat
                        ? _c(
                            "z-button",
                            {
                              attrs: { type: "default" },
                              on: { click: _vm.scanBarcode },
                            },
                            [_vm._v("扫码")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: { visible: _vm.dialogVisible },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
              },
            },
            [
              _c("img", {
                attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
              }),
            ]
          ),
          _c(
            "div",
            { staticClass: "container info-card" },
            [
              _c(
                "el-table",
                {
                  staticClass: "ordering-list",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.codesList },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "waybill", label: "入仓单号" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "user_code", label: "用户编码" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "package_num", label: "订单号" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }