
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import {
	isPrinterConnected,
	isWechat,
	printWithLocalPrinter
} from '@/utils/utils'
import { carrierMatch } from '@/utils/carrier-cn'
import printJS from 'print-js'

@Component({
	components: { ZButton }
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private isWechat = false

	private isLoading = false
	private codesList: any = []
	private searchForm: any = {
		waybill: '',
		user_code: '',
		remark: '',
		cate: '',
		work_order_content: '',
		fileList: []
	}

	private uploadFileList = []

	private autoPrintLabel = false

	private dialogVisible = false
	private dialogImageUrl = ''

	private validateForm = {
		waybill: [
			{ required: true, message: '请输入入仓单号/GLP单号', trigger: 'blur' }
		],
		remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
		cate: [{ required: true, message: '选择分类', trigger: 'blur' }]
	}

	@Watch('searchForm.waybill')
	onInputLogisticsCode() {
		const { waybill, user_code, remark } = this.searchForm
		this.searchForm.work_order_content = `${user_code}  ${waybill} ${remark}`
	}

	@Watch('searchForm.user_code')
	onInputUserCode() {
		const { waybill, user_code, remark } = this.searchForm
		this.searchForm.work_order_content = `${user_code}  ${waybill} ${remark}`
	}

	@Watch('searchForm.remark')
	onInputRemark() {
		const { waybill, user_code, remark } = this.searchForm
		this.searchForm.work_order_content = `${user_code} ${waybill} ${remark}`
	}

	async created() {
		this.isWechat = isWechat()
		this.$notify({
			title: '提示',
			dangerouslyUseHTMLString: true,
			duration: 0,
			offset: 200,
			message:
				'<p>分类说明</p> <p>1. 未下单: 有用户编码或者无用户编码等无法替用户进行下单作业</p> <p>2. 需退回: 违禁品或者超过尺寸等无法正常出货的包裹</p><p>3. 其他: 包裹破损等</p>'
		})
	}
	async initJSSdk() {
		if (!this.isWechat) return false
		const url = location.href.split('#')[0]
		const { data } = await this.$axios.post('v1/jobline/js-sdk-config', {
			url
		})
		this.$wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			...data,
			jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
		})
	}

	querySearch(value: string, cb: Function) {
		const list = [
			{
				value: '无地址'
			},
			{
				value: '无用户编码'
			},
			{
				value: '电商未下单'
			},
			{
				value: '欧盟需用户自行下单'
			},
			{
				value: '超尺寸需退回'
			},
			{
				value: '电商GLP订单重复'
			}
		]
		cb(list)
	}

	onRemoveImage(file: any, fileList: any) {
		this.searchForm.fileList.forEach((el: any, index: number) => {
			if (el.name == file.name) {
				this.searchForm.fileList.splice(index, 1)
			}
		})
	}
	async uploadFile(files: any) {
		const { file } = files
		const fileUpload = {
			name: file.name,
			percent: 0,
			file: file,
			url: '',
			path: ''
		}
		const formData = new FormData()
		formData.append('file', file)
		const uploader = this.$axios.post('/v1/jobline/upload-photo', formData, {
			timeout: 60 * 1000,
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			onUploadProgress(e: any) {
				const percentCompleted = Math.round((e.loaded * 100) / e.total)
				fileUpload.percent = percentCompleted
			},
			timeoutErrorMessage: '网络环境较差，上传图片超时'
		})

		this.searchForm.fileList.push(fileUpload)
		const { data } = await uploader.catch(() => {
			this.searchForm.fileList.forEach((el: any, index: number) => {
				if (el.name == fileUpload.name) {
					this.searchForm.fileList.splice(index, 1)
				}
			})
			throw new Error('error')
		})
		const { url, path } = data[0]
		fileUpload.url = url
		fileUpload.path = path
	}

	async handlePictureCardPreview(file: any) {
		this.dialogImageUrl = file.url
		this.dialogVisible = true
	}

	async scanBarcode() {
		if (!this.$wx) return false
		await this.initJSSdk()
		this.$wx.ready(() => {
			this.$wx.scanQRCode({
				needResult: 1,
				scanType: ['barCode'],
				success: (res: any) => {
					const result = res.resultStr.split(',')
					const code = result[result.length - 1]
					this.searchForm.waybill = code
					this.save()
				}
			})
		})
	}
	async save() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { waybill, user_code, remark, shelf_code } = this.searchForm
		if (remark.trim() == '违禁品') {
			this.$message.error('请说明具体物品,不能只填写违禁品')
			return
		}
		let work_order_content = this.searchForm.work_order_content
		if (!waybill) return
		const waybillCompany = carrierMatch(waybill)
		if (!work_order_content) {
			work_order_content = `${user_code} , ${waybill} ${remark}`
		}
		const { data } = await this.$axios
			.post('/v1/jobline/waybill/exception', {
				waybill,
				user_code,
				shelf_code,
				waybill_company: waybillCompany?.name || '',
				remark: this.searchForm.remark,
				work_order_content: work_order_content,
				photos_url: this.searchForm.fileList.map((el: any) => el.path),
				cate: this.searchForm.cate
			})
			.catch(() => {
				this.searchForm.user_code = ''
				return {
					data: null
				}
			})
		if (!data) return
		this.searchForm = {
			waybill: '',
			user_code: '',
			remark: '',
			cate: '',
			work_order_content: '',
			fileList: []
		}
		this.uploadFileList = []
		this.$message.success('录入成功')
		this.codesList.unshift(data)
		if (this.autoPrintLabel) {
			await this.printLabel(data.waybill_num)
		}
	}

	async printLabel(package_num: string) {
		const isConnected = await isPrinterConnected()
		const url = `${process.env.VUE_APP_API_BASE_URL}v1/jobline/generate/exception-waybill/${package_num}`
		if (!isConnected) {
			return printJS(url)
		}
		const list: any[] = []
		list.push({
			package_num,
			title: '异常件',
			url
		})
		await printWithLocalPrinter(list)
	}
}
