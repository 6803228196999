export const carrier = [
	{
		value: 'JDL',
		name: '京东快递',
		rule: '^JD[0-9]{13}$'
	},
	{
		value: 'ZJS',
		name: '宅急送',
		rule: '^[a-zA-Z0-9]{10}$|^(42|16)[0-9]{8}$|^A[0-9]{12}'
	},
	{
		value: 'JTSD',
		name: '极兔快递',
		rule: '^[0-9]{13}|^[JjUu][Tt][0-9]{13}$'
	},
	{
		value: 'SF',
		name: '顺丰速运',
		rule: '^SF[0-9]{13,15}$'
	},
	{
		value: 'STO',
		name: '申通快递',
		rule:
			'^((268|368|568|668|868|688|888|768|968|588)[0-9]{9})$|^((11|22|40|41|42|43|45|46|47|48|49)[0-9]{10})$|^((660|661|662|663|664|665|666|667|669|880|881|882|884|885|886|887|889)[0-9]{10})$|^((33|37|44|55|77|99)[0-9]{11})$|^((77)[0-9]{13})$|^(777|773|770)[0-9]{12}'
	},
	{
		value: 'EMS',
		name: 'EMS',
		rule:
			'^[A-Z]{2}[0-9]{9}[A-Z]{2}$|^(10|11)[0-9]{11}$|^(50|51)[0-9]{11}$|^(95|97)[0-9]{11}$'
	},
	{
		value: 'YUNDA',
		name: '韵达快递',
		rule:
			'^(10|11|12|13|14|15|16|17|19|18|50|55|58|80|88|66|31|77|39|46)[0-9]{13}$|^[0-9]{13}$'
	},
	{
		value: 'ZTO',
		name: '中通快递',
		rule:
			'^((768|765|778|828|618|680|518|528|688|010|880|660|805|988|628|205|717|718|728|761|762|763|701|757|719|751|358|100|200|118|128|689|738|359|779|852)[0-9]{9})$|^((5711|2008|7380|1180|2009|2013|2010|1000|1010)[0-9]{8})$|^((8010|8021|8831|8013)[0-9]{6})$|^((1111|90|36|11|50|53|37|39|91|93|94|95|96|98)[0-9]{10})$|^((a|b|h)[0-9]{13})$|^((90|80|60)[0-9]{7})$|^((80|81)[0-9]{6})$|^((21|23|24|25|93|94|95|96|97|110|111|112|113|114|115|116|117|118|119|121|122|123|124|125|126|127|128|129|130|131)[0-9]{8})$|^(100|101|102|103|104|105|106|107|503|504|505|506|507)[0-9]{10}$|^(4)[0-9]{11}$|^(120)[0-9]{9}$|^(780)[0-9]{9}$|^(881)[0-9]{9}$|^(882|885)[0-9]{9}$|^(91|92)[0-9]{10}$|^(54|55|56)[0-9]{10}$|^(63)[0-9]{10}$|^(7)[0-9]{9}$|^(64)[0-9]{10}$|^(72)[0-9]{10}$|^(220|221|223|224|225|226|227|228|229)[0-9]{7}$|^(21|22|23|24|25|26|27|28|29)[0-9]{10}$|^3[0-9]{9}$|^2710[0-9]{11}$|^731[0-9]{11}$|^751[0-9]{11}$|^7320[0-9]{10}$'
	},
	{
		value: 'HTKY',
		name: '百世快递',
		rule:
			'^((A|B|D|E)[0-9]{12})$|^(BXA[0-9]{10})$|^(K8[0-9]{11})$|^(02[0-9]{11})$|^(000[0-9]{10})$|^(C0000[0-9]{8})$|^((21|22|23|24|25|26|27|28|29|30|31|32|33|34|35|36|37|38|39|61|63)[0-9]{10})$|^((50|51)[0-9]{12})$|^7[0-9]{13}$|^6[0-9]{13}$|^58[0-9]{14}|^55[0-9]{13}$'
	},
	{
		value: 'YTO',
		name: '圆通速递',
		rule:
			'^[A-Za-z0-9]{2}[0-9]{10}$|^[A-Za-z0-9]{2}[0-9]{8}$|^[6-9][0-9]{17}$|^[DD]{2}[8-9][0-9]{15}$|^[Y][0-9]{12}$'
	},
	{
		value: 'QFKD',
		name: '全峰快递',
		rule:
			'^[0-6|9][0-9]{11}$|^[7][0-8][0-9]{10}$|^[0-9]{15}$|^[S][0-9]{9,11}(-|)P[0-9]{1,2}$|^[0-9]{13}$|^[8][0,2-9][0,2-9][0-9]{9}$|^[8][1][0,2-9][0-9]{9}$|^[8][0,2-9][0-9]{10}$|^[8][1][1][0][8][9][0-9]{6}$'
	},
	{
		value: 'TTKDEX',
		name: '天天快递',
		rule: '^[0-9]{12}$'
	},
	{
		value: 'EYB',
		name: 'EMS经济快递',
		rule:
			'^[A-Z]{2}[0-9]{9}[A-Z]{2}$|^(10|11)[0-9]{11}$|^(50|51)[0-9]{11}$|^(95|97)[0-9]{11}$'
	},
	{
		value: 'UC',
		name: '优速快递',
		rule:
			'^VIP[0-9]{9}|V[0-9]{11}|[0-9]{12}$|^LBX[0-9]{15}-[2-9AZ]{1}-[1-9A-Z]{1}$|^(9001)[0-9]{8}$'
	},
	{
		value: 'DBKD',
		name: '德邦快递',
		rule: '^[5789]d{9}$'
	},
	{
		value: 'GTO',
		name: '国通快递',
		rule:
			'^(3(([0-6]|[8-9])d{8})|((2|4|5|6)d{9})|(7(?![0|1|2|3|4|5|7|8|9])d{9})|(8(?![2-9])d{9})|(2|4)d{11})$'
	},
	{
		value: 'SURE',
		name: '速尔快递',
		rule: '^(SUR)[0-9]{12}$|^[0-9]{12}$'
	},
	{
		value: 'FEDEX',
		name: '联邦快递',
		rule: '^[0-9]{12}$'
	},
	{
		value: 'SHQ',
		name: '华强物流',
		rule: '^[A-Za-z0-9]*[0|2|4|6|8]$'
	},
	{
		value: 'UAPEX',
		name: '全一快递',
		rule: '^d{12}|d{11}$'
	},
	{
		value: 'HOAU',
		name: '天地华宇',
		rule: '^[A-Za-z0-9]{8,9}$'
	},
	{
		value: 'BEST',
		name: '百世物流',
		rule: '^[0-9]{11,12}$'
	},
	{
		value: 'LB',
		name: '龙邦速递',
		rule:
			'^[0-9]{12}$|^LBX[0-9]{15}-[2-9AZ]{1}-[1-9A-Z]{1}$|^[0-9]{15}$|^[0-9]{15}-[1-9A-Z]{1}-[1-9A-Z]{1}$'
	},
	{
		value: 'XB',
		name: '新邦物流',
		rule: '^[0-9]{8}$|^[0-9]{10}$'
	},
	{
		value: 'FAST',
		name: '快捷快递',
		rule:
			'^(?!440)(?!510)(?!520)(?!5231)([0-9]{9,13})$|^(P330[0-9]{8})$|^(D[0-9]{11})$|^(319)[0-9]{11}$|^(56)[0-9]{10}$|^(536)[0-9]{9}$'
	},
	{
		value: 'POSTB',
		name: '邮政快递包裹',
		rule:
			'^([GA]|[KQ]|[PH]){2}[0-9]{9}([2-5][0-9]|[1][1-9]|[6][0-5])$|^[99]{2}[0-9]{11}$|^[96]{2}[0-9]{11}$|^[98]{2}[0-9]{11}$'
	},
	{
		value: 'NEDA',
		name: '能达速递',
		rule: '^((88|)[0-9]{10})$|^((1|2|3|5|)[0-9]{9})$|^(90000[0-9]{7})$'
	},
	{
		value: 'BJRFD-001',
		name: '如风达配送',
		rule: '^[\x21-\x7e]{1,100}$'
	},
	{
		value: 'DBL',
		name: '德邦物流',
		rule: '^[5789]d{9}$'
	},
	{
		value: 'YCT',
		name: '黑猫宅急便',
		rule: '^[0-9]{12}$'
	},
	{
		value: 'LTS',
		name: '联昊通',
		rule: '^[0-9]{9,12}$'
	},
	{
		value: 'CNEX',
		name: '佳吉快递',
		rule: '^[7,1,9][0-9]{9}$'
	},
	{
		value: 'HZABC',
		name: '飞远(爱彼西)配送',
		rule:
			'^[0-9]{10,11}$|^T[0-9]{10}$|^FYPS[0-9]{12}$|^LBX[0-9]{15}-[2-9AZ]{1}-[1-9A-Z]{1}$'
	},
	{
		value: 'XFWL',
		name: '信丰物流',
		rule: '^130[0-9]{9}|13[7-9]{1}[0-9]{9}|18[8-9]{1}[0-9]{9}$'
	},
	{
		value: 'ESB',
		name: 'E速宝',
		rule: '[0-9a-zA-Z-]{5,20}'
	},
	{
		value: 'GDEMS',
		name: '广东EMS',
		rule: '^[a-zA-Z]{2}[0-9]{9}[a-zA-Z]{2}$'
	},

	{
		value: 'QRT',
		name: '增益速递',
		rule: '^[0-9]{12,13}$'
	}
]

export const carrierMatch = (code: string): any => {
	if (!code) return null
	for (const item of carrier) {
		const regex = new RegExp(item.rule)
		const isMatch = regex.test(code)
		if (isMatch) {
			return {
				name: item.name,
				value: item.value
			}
		}
	}
	return null
}
